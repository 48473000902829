import { getParam } from '@integration-frontends/apps/common/utils';
import { initEvent } from '@integration-frontends/apps/integration-design-huddle/src/app/common/design-huddle-events';
import { loadSegment } from '@integration-frontends/common/analytics';
import { init } from '@integration-frontends/common/app';
import { SetApiKey } from '@integration-frontends/common/auth/ui';
import { BFPanel, Loader } from '@integration-frontends/common/ui';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import React, { Suspense, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { Navigate, Route, Routes, unstable_HistoryRouter as Router } from 'react-router-dom';
import { dynamicActivate } from '../../../../i18n';
import { AttachmentUploader } from '../../../../libs/integration/ui/src/lib/attachment-uploader';
import { SelectAttachment } from '../../../common/src';
import { Header } from '../../../common/src/ui/header';
import { environment } from '../environments/environment';
import './app.scss';
import { history, store } from './store';

export enum Size {
  Fixed = 'fixed',
  Full = 'full',
}

export function App() {
  const size = getParam('size') || Size.Fixed;
  const [receivedInitEvent, setReceivedInitEvent] = useState(false);

  loadSegment(environment);

  useEffect(() => {
    const message = {
      event: 'Panel Initialized',
    };
    console.log('Sending to origin', origin);
    const targetWindow = window.parent || window.opener;
    targetWindow.postMessage(message, '*');
  }, []);

  useEffect(() => {
    initEvent.then(() => {
      setReceivedInitEvent(true);
      store.dispatch(init({ name: 'integration-dh' }));
    });
    dynamicActivate('en');
  }, []);

  if (!receivedInitEvent) return null;

  return (
    <div
      style={{
        width: size === Size.Full ? '100%' : '85%',
      }}
    >
      <I18nProvider i18n={i18n}>
        <Provider store={store}>
          <Router history={history}>
            <BFPanel
              className="flex flex-col overflow-y-hidden"
              style={{ height: size === Size.Full ? '100%' : '100vh', minHeight: 640 }}
            >
              <Suspense
                fallback={
                  <div className="flex h-full w-full items-center justify-center">
                    <Loader />
                  </div>
                }
              >
                <Routes>
                  <Route
                    path="/getting-started"
                    element={
                      <div id="getting-started-container">
                        <SetApiKey />
                      </div>
                    }
                  />
                  <Route path="/select-attachment/*" element={<SelectAttachment />} />
                  <Route
                    path="/upload"
                    element={
                      <>
                        <Header />
                        <AttachmentUploader />
                      </>
                    }
                  />
                  <Route path="*" element={<Navigate to={'/select-attachment'} replace />} />
                </Routes>
              </Suspense>
            </BFPanel>
          </Router>
        </Provider>
      </I18nProvider>
    </div>
  );
}

export default App;
