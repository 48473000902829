import { loginSuccess, logout } from '@integration-frontends/common/auth/core/application';
import { DI_CONTAINER, getObservabilityService } from '@integration-frontends/core';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { IDENTITY_STORE_TOKEN, IIdentityStore } from '../common';
import { sendSegmentIdentifyAction } from '@integration-frontends/common/analytics';
import { push } from 'redux-first-history';
import { environment } from '../../environments/environment';

function* onLoginSuccess(action: ReturnType<typeof loginSuccess>) {
  const identityStore: IIdentityStore = DI_CONTAINER.get(IDENTITY_STORE_TOKEN);
  const { identity } = action.payload;
  yield call(identityStore.set, identity);
  yield call(getObservabilityService().sendIdentity, identity, environment?.environment);
  yield put(sendSegmentIdentifyAction({ identity }));
  yield put(push('/select-attachment'));
}

function* onLogout() {
  yield call(getObservabilityService().stopSessionRecording);
  const identityStore: IIdentityStore = DI_CONTAINER.get(IDENTITY_STORE_TOKEN);
  yield call(identityStore.set, null);
  yield put(push('/getting-started'));
}

export function* authWorkflow() {
  yield all([takeEvery(loginSuccess.type, onLoginSuccess), takeEvery(logout.type, onLogout)]);
}
