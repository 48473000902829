import { AttachmentActionContainerBaseProps } from '@integration-frontends/integration/ui';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  INTEGRATION_COMMON_NAMESPACE,
  PLACE_FOREGROUND_LABEL,
  PLACE_SUCCESS_LABEL_KEY,
} from '@integration-frontends/integration/ui/common/i18n';
import React, { useCallback, useEffect, useState } from 'react';
import { IconPlaceForeground } from '@integration-frontends/common/ui';
import { attachmentActionClicked } from '../../actions';
import { DesignHuddleAttachmentAction } from '../../model';
import { initPayload } from '../../design-huddle';

export function PlaceForegroundActionContainer({
  selectedAttachments,
  render,
}: AttachmentActionContainerBaseProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  const id = 'foreground';
  const [label, setLabel] = useState<string>(t(PLACE_FOREGROUND_LABEL));
  const [success, setSuccess] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const Icon = () => <IconPlaceForeground active={enabled} />;

  useEffect(() => {
    initPayload.then((payload) => {
      setEnabled(payload?.foreground);
    });
  }, []);

  const onClick = useCallback(() => {
    dispatch(
      attachmentActionClicked({
        action: DesignHuddleAttachmentAction.PlaceForeground,
        attachment: selectedAttachments[0],
      }),
    );

    setLabel(t(PLACE_SUCCESS_LABEL_KEY));
    setSuccess(true);
  }, [selectedAttachments]);

  return render({
    id,
    Icon,
    label,
    loading: false,
    success,
    enabled,
    onClick,
    ariaLabel: t(PLACE_FOREGROUND_LABEL),
  });
}
