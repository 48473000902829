import { takeEvery } from 'redux-saga/effects';
import { attachmentActionClicked } from '../actions';
import { fetchAttachmentsDetails } from '@integration-frontends/integration/core/application/select-attachment/state/effects/common/fetch-attachments-details';
import { call } from 'typed-redux-saga';

const handler = function* (action: ReturnType<typeof attachmentActionClicked>) {
  const [attachmentDetail] = yield call(fetchAttachmentsDetails, [action.payload.attachment]);
  // CX468 fix: Design Huddle is checking the format only by the extension, but we're doing the transformations on the
  // CDN. Satisify this limitation by changing the extension on the filename as our CDN supports that.
  const url = new URL(attachmentDetail.url);
  url.pathname = url.pathname.replace(`.${attachmentDetail.extension}`, `.${attachmentDetail.mediaType.toLowerCase()}`);
  const message = {
    event: 'selectedAttachmentForDesignHuddle',
    payload: {
      ...attachmentDetail.dto,
      ...attachmentDetail.dto.attributes,
      url: url.toString(), // Design Huddle is looking at the "url" field, not the "cdn_url" field.
      cdn_url: attachmentDetail.url, // Leaving "as-is" in the event Design Huddle updates their implementation.
      placementType: action.payload.action,
    },
  };

  console.log('Sending', message);
  const targetWindow = window.parent || window.opener;
  targetWindow.postMessage(message, '*');
};

export function* attachmentActionClickedWorkflow() {
  yield takeEvery(attachmentActionClicked, handler);
}
