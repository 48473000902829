export interface DHInitPayload {
  foreground: boolean;
  background: boolean;
}

export const initPayload: Promise<DHInitPayload> = new Promise((resolve) => {
  window.addEventListener('message', handleDesignHuddleMessage);

  function handleDesignHuddleMessage(event: MessageEvent) {
    if (event.data.event === 'BF_DSHD_FILTER') {
      resolve(event.data.payload);
    }
  }
});
