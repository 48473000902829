import { placeAttachmentWorkflow } from '@integration-frontends/apps/common/workflows';
import { all, call, fork } from 'typed-redux-saga';
import { attachmentActionClickedWorkflow } from './attachment-action-clicked-workflow';
import { authWorkflow } from './auth-workflow';
import { backToHomeEffects } from './back-to-home';
import { externalLinkClickedEffects } from './external-link-clicked-effects';
import { initWorkflow } from './init-workflow';
import { routePersistenceEffects } from './route-persistence';
import { getObservabilityService } from '@integration-frontends/core';

export function* rootWorkflow() {
  try {
    yield initWorkflow();
    yield all([
      fork(attachmentActionClickedWorkflow),
      fork(authWorkflow),
      fork(backToHomeEffects),
      fork(externalLinkClickedEffects),
      fork(placeAttachmentWorkflow),
      fork(routePersistenceEffects),
    ]);
  } catch (e) {
    yield call(getObservabilityService().addError, e);
  }
}
