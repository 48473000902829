import { withPayloadType } from '@integration-frontends/common/utils/redux';
import { Attachment, AttachmentVariant } from '@integration-frontends/integration/core/model';
import { createAction } from '@reduxjs/toolkit';
import { DesignHuddleAttachmentAction } from './model';

export const init = createAction('INTEGRATION_PANEL_UI_INIT');
export const getStarted = createAction('INTEGRATION_PANEL_UI_GET_STARTED');

export const attachmentActionClicked = createAction(
  'ATTACHMENT_ACTION_CLICKED',
  withPayloadType<{
    action: DesignHuddleAttachmentAction;
    attachment: Attachment | AttachmentVariant;
  }>(),
);
