import {
  AttachmentActionContainerBaseProps,
  IAttachmentActionProvider,
} from '@integration-frontends/integration/ui';
import { PlaceForegroundActionContainer } from '../ui/attachment-selector/place-foreground-action-container';
import { PlaceBackgroundActionContainer } from '../ui/attachment-selector/place-background-action-container';

export class AttachmentActionsProviderDesignHuddleStrategy implements IAttachmentActionProvider {
  getActionContainers(): React.FunctionComponent<AttachmentActionContainerBaseProps>[] {
    return [PlaceForegroundActionContainer, PlaceBackgroundActionContainer];
  }
}
