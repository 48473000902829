/**
 * Copied from legacy repo
 */
import { getParam } from '@integration-frontends/apps/common/utils/get-param';

export const openLinks = getParam('openLinks') !== 'false';
console.log('openLinks:', openLinks);
console.log(typeof openLinks);

export const attachmentFields = (getParam('attachment_fields') || '').split(',');
console.log('attachmentFields:', attachmentFields);

export const PERSIST_ROUTE = !!getParam('persistRoute');
console.log('Persist route : ' + PERSIST_ROUTE);
