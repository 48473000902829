import { takeEvery } from 'typed-redux-saga';
import { PERSIST_ROUTE } from '../common/utilities';
import { SAVED_ROUTE_KEY } from './common';
import { StorageMethods, useStorage } from '@apps-common/utils';
import { locationChangeAction } from 'redux-first-history/build/es6/actions';
import { LOCATION_CHANGE } from 'redux-first-history';

function handler(action: ReturnType<typeof locationChangeAction>) {
  const { pathname, search } = action.payload.location;

  if (PERSIST_ROUTE) {
    const route = `${pathname}${search}`;
    console.log('Persisting route: ' + route);
    useStorage(StorageMethods.SetItem, SAVED_ROUTE_KEY, route);
  }
}

export function* routePersistenceEffects() {
  yield takeEvery(LOCATION_CHANGE, handler);
}
