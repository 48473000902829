import { Extension } from '@integration-frontends/integration/core/model';

export interface DesignHuddleInitPayload {
  foreground: boolean;
  background: boolean;
  file_types: Extension[];
}

export interface DesignHuddleInitEvent {
  event: string;
  payload: DesignHuddleInitPayload;
}

export const initEvent: Promise<DesignHuddleInitPayload> = new Promise((resolve) => {
  window.addEventListener('message', (event: MessageEvent<DesignHuddleInitEvent>) => {
    if (event.data.event === 'BF_DSHD_FILTER') {
      resolve(event.data.payload);
    }
  });
});
